/*! core.scss | Nephos | CSS Ninja */

/* ==========================================================================
Nephos core 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. CSS Imports
1. Resets and base setup
=============================================================================
***/

/* ==========================================================================
0. CSS Imports
========================================================================== */

@import 'partials/theme-default';
@import 'partials/colors';
@import 'partials/sidebar';
@import 'partials/quickview';
@import 'partials/cards';
@import 'partials/layout';
@import 'partials/categories';
@import 'partials/account';
@import 'partials/cart';
@import 'partials/orders';
@import 'partials/product';
@import 'partials/checkout';
@import 'partials/auth';
@import 'partials/buttons';
@import 'partials/search';
@import 'partials/filters';
@import 'partials/checkboxes';
@import 'partials/forms';
@import 'partials/invoice';
@import 'partials/pageloader';
@import 'partials/elements';
@import 'partials/utils';
@import 'partials/responsive';


/* ==========================================================================
1. Resets and base setup
========================================================================== */

a {
    color: $primary;
}

