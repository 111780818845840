/*! _forms.scss | Nephos | CSS Ninja */

/* ==========================================================================
Form controls styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Textareas
1. Form Controls
2. Inputs
=============================================================================
***/

/* ==========================================================================
0. Textareas
========================================================================== */

textarea.textarea {
    color: $muted-grey;
    box-shadow: none;
    overflow: hidden;
    transition: all 0.8s;
    //focus colors
    &:focus {
        border: 1px solid $fade-grey;
        box-shadow: none !important;
    }
    &.is-primary-focus:focus {
        border-color: $primary;
        ~ span.icon i {
            color: $primary;
        }
    }
    &.is-secondary-focus:focus {
        border-color: $secondary;
        ~ span.icon i {
            color: $secondary;
        }
    }
    &.is-accent-focus:focus {
        border-color: $accent;
        ~ span.icon i {
            color: $accent;
        }
    }
    &.is-grey-focus:focus {
        border-color: $title-grey;
        ~ span.icon i {
            color: $title-grey;
        }
    }
    &.is-default-focus:focus {
        border-color: $muted-grey;
        ~ span.icon i {
            color: $muted-grey;
        }
    }
    //growing textarea
    &.is-grow {
        min-height: 40px !important;
        height: 40px;
        resize: none;
        transition: all 0.5s;
        &:focus {
            height: 130px !important;
            border-color: $fade-grey;
        }
    }
    //embeded button inside textarea
    &.is-button {
        min-height: 60px !important;
        height: 60px !important;
        resize: none;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none !important;
        transition: all 0.6s;
        &:hover {
            border-color: $fade-grey;
        }
        &:focus {
            height: 130px !important;
            padding-bottom: 40px !important;
            border-color: $fade-grey;
        }
        + .textarea-button {
            display: flex;
            justify-content: flex-end;
            position: relative;
            border: 1px solid $fade-grey;
            border-top: none !important;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background: $light-grey;
            button {
                margin: 10px 7px 7px 7px;
                font-size: 0.85rem;
                padding: 4px 10px 8px 10px;
                width: 92px;
                //border-radius: 50px;
            }
        }
    }
}

/* ==========================================================================
1. Form Controls
========================================================================== */

.control {
    &.control-medium {
        padding: 20px;
    }
    &.is-input-wrapper {
        margin: 10px 0;
        background: #f7f7f7;
    }
}

/* ==========================================================================
2. Inputs
========================================================================== */

.input {
    transition: all .3s;
    &.is-minimal {
        box-shadow: none !important;
        &:focus {
            box-shadow: none !important;
            border: 1px solid $fade-grey;
        }
    }
    &.is-default {
        box-shadow: none;
        color: $muted-grey-bold;
        &:focus {
            border: 1px solid $fade-grey;
        }
    }
}